<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Ref')" label-for="ref" debounce="500">
            <b-form-input id="ref" v-model="refValue" />
          </b-form-group>
        </b-col>

        <!-- Field: Client -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              label="name"
              input-id="client"
              v-model="clientValue"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group
            :label="$t('Date')"
            label-for="client"
            style="font-size: 0.857rem"
          >
            <flat-pickr
              id="client"
              v-model="rangeDate"
              :config="{
                mode: 'range',
                dateFormat: 'Y-m-d',
              }"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-card no-body class="mb-0">
      <b-table
        id="scrolle-table"
        ref="refOrderListTable"
        class="position-relative"
        :items="fetchOrders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="consulteOrder(data.item)"
          >
            <feather-icon icon="Edit2Icon" size="16" />
          </b-button>

          <b-modal
            :ref="'consulte-order-' + data.item.id"
            centered
            ok-only
            :ok-title="$t('Arrived')"
            size="xl"
            @hidden="onConsulteOrderModalHidden"
            :title="$t('Consultation')"
            hide-footer
          >
            <div class="flex_box">
              <table style="width: max-content !important">
                <tbody>
                  <tr>
                    <td class="color border padding_table">
                      <strong>{{ $t("Client") }}</strong>
                    </td>
                    <td class="border padding_table">{{ client }}</td>
                  </tr>
                  <tr class="border">
                    <td class="color border padding_table">
                      <strong>{{ $t("Order source") }}</strong>
                    </td>
                    <td class="border padding_table">{{ orderSource }}</td>
                  </tr>
                </tbody>
              </table>
              <table style="width: max-content !important">
                <tbody>
                  <tr>
                    <td class="color border padding_table">
                      <strong>{{ $t("Order date") }}</strong>
                    </td>
                    <td class="border padding_table">{{ orderDate }}</td>
                  </tr>
                  <tr class="border">
                    <td class="color border padding_table">
                      <strong>{{ $t("Order add by") }}</strong>
                    </td>
                    <td class="border padding_table">{{ operator }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-table
              id="scrolle-table"
              show-empty
              :empty-text="$t('No matching records found')"
              :items="orderLines"
              :fields="fields"
              responsive
              @row-selected="onRowSelected"
            >
              <template #cell(available_qty)="data">
                {{
                  !data.item.order_line
                    ? data.item.available_qty
                    : calcAvailableQty(data.item).toFixed(3)
                }}
              </template>
              <template #cell(state)="data">
                <!-- <custom-order-line-state-badge :state="data.item.state" /> -->
                {{ data.item.state.name }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  v-if="calcAvailableQty(data.item) > 0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="opemReturnOrderLineModal(data)"
                >
                  <!-- @click="returnOrderLine(data.item)" -->
                  <feather-icon icon="EditIcon" size="16" />
                </b-button>

                <b-modal
                  :ref="'return-orderline-' + data.index + '-modal'"
                  ok-only
                  ok-title="Ok"
                  centered
                  title="Alert"
                  @hidden="quantityValue = null"
                  @ok="returnOrderLine"
                >
                  <b-form-group>
                    <label for="email">{{ $t("Quantity Return") }}</label>
                    <b-form-input
                      id="email"
                      type="number"
                      v-model="quantityValue"
                      :placeholder="$t('Quantity')"
                    />
                  </b-form-group>
                </b-modal>
              </template>
            </b-table>
          </b-modal>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BAlert,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import useReturnTrackingList from "./useReturnTrackingList";
import Ripple from "vue-ripple-directive";
import CustomOrderLineStateBadge from "@/views/ui/CustomOrderLineStateBadge.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    CustomOrderLineStateBadge,
    flatPickr,
    BTab,
    BTabs,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    BCardText,
    BBadge,
  },
  props: {
    clients: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "client_annexe", label: this.$t("client annexe") },
        { key: "product", label: this.$t("product") },
        { key: "available_qty", label: this.$t("available qty") },
        { key: "available_piece", label: this.$t("available piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
        { key: "state", label: this.$t("state") },
        { key: "actions", label: this.$t("actions") },
      ],
      orderLines: [],
      selectedOrderLines: [],
      selectedClient: null,
      timeoutId: null,
      order: null,
      orderline: null,
      quantityValue: null,
      operator:null,
      orderSource:null,
      orderDate:null,
      client:null,
    };
  },

  methods: {
    refreshTable() {
      this.refetchData();
    },
    calcAvailableQty(orderline) {
      return (
        parseFloat(orderline.available_qty) -
        orderline.order_line
          .map((e) => parseFloat(e.ordered_qty))
          .reduce((a, b) => a + b, 0)
      );
    },
    async returnOrderLine(bvModal) {
      let available_qty = parseFloat(this.orderline.available_qty);
      let qty = !this.orderline.order_line
        ? available_qty
        : this.calcAvailableQty(this.orderline);

      if (qty < parseFloat(this.quantityValue)) {
        bvModal.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t(
              "Quantity must equal or less than available quantity"
            ),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      try {
        await instance.patch(`orders/order-lines/${this.orderline.id}`, {
          action: "return",
          quantity: this.quantityValue,
        });

        await this.consulteOrder(this.order);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.quantityValue = null;
      }
    },
    opemReturnOrderLineModal(data) {
      this.$refs[`return-orderline-${data.index}-modal`].show();
      this.orderline = data.item;
    },
    async consulteOrder(order) {
      (this.orderDate = order.date),
        (this.orderDate = this.orderDate.split(" ")[0]);
      this.client = order.client.name;
      if (order.source != null) {
        this.orderSource = order.source.name;
      }
      if (order.operator != null) {
        this.operator = order.operator.name;
      }
      try {
        this.orderLines = [];
        this.order = order;

        const res = await instance.get(`/orders/${order.id}/order-lines/`, {
          params: {
            state_id: "delivered",
            role: "delivery-return",
          },
        });

        res.data.forEach((orderLine) => {
          const {
            product_shortcut
          } = orderLine.product_combination;
          const {
            state,
            ordered_qty,
            piece,
            available_qty,
            available_piece,
            order_line,
            shipping_type,
            shipping_date,
            emergency_degree,
            client_annexe,
          } = orderLine;
          if(emergency_degree!=null){
            var emergency_degree_name = emergency_degree.name;
          }else{
            var emergency_degree_name = null;
          }

          if(client_annexe!=null){
            var client_annexe_name = client_annexe.name;
          }else{
            var client_annexe_name = null;
          }

          this.orderLines.push({
            id: orderLine.id,
            product:product_shortcut,
            state: state,
            ordered_qty: ordered_qty,
            available_qty: available_qty,
            available_piece: available_piece,
            shipping_type: shipping_type.name,
            shipping_date: shipping_date,
            piece: piece,
            state: state,
            ordered_qty: ordered_qty,
            piece: piece,
            available_qty: available_qty,
            ref: order.ref,
            emergency_degree:emergency_degree_name,
            order_line,
            client_annexe:client_annexe_name,
          });
        });

        this.$refs[`consulte-order-${order.id}`].show();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    onRowSelected(orderLine) {
      this.selectedOrderLines = orderLine;
    },
    async onConsulteOrderModalHidden() {
      //   this.refetchData();
      this.orderLines = [];
    },

    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clientsRes = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      dockValue,
      rangeDate,
    } = useReturnTrackingList("delivered");

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      dockValue,
      rangeDate,
    };
  },
};
</script>

<style>
td.color {
  background-color: #f3f2f7;
}
table#scrolle-table {
  white-space: nowrap;
}
</style>
