<template>
  <component is="b-card">
    <b-tabs pills>
      <b-tab active @click="orderReturnClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Order Return") }}</span>
        </template>

        <return-tracking ref="orderReturnRef" :clients="clients" />
      </b-tab>
      <b-tab @click="consulteReturnTrackingClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{
            $t("Consulte Order Return")
          }}</span>
        </template>
        <consulte-return-tracking
          ref="consulteReturnTrackingRef"
          :clients="clients"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReturnTracking from "./ReturnTracking.vue";
import ConsulteReturnTracking from "./ConsulteReturnTracking.vue";
import instance from "@/libs/axios";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ReturnTracking,
    ConsulteReturnTracking,
  },
  data() {
    return {
      clients: [],
    };
  },
  methods: {
    orderReturnClicked() {
      this.$refs["orderReturnRef"].refreshTable();
    },
    consulteReturnTrackingClicked() {
      this.$refs["consulteReturnTrackingRef"].refreshTable();
    },
  },
  async mounted() {
    try {
      const res = await instance.get("/parameters/clients/");
      this.clients = res.data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
};
</script>

<style></style>
